import cfg from '@/config';
import http from '../utils/fetch';

export const fetchPois = async (destinationId, token) => {
  const url = `${cfg.cmsApiUrl}/api/v3/sites/${destinationId}/pois?status=all`;
  const res = await http.getJSON(url, token);
  return res;
};

export const fetchenabledPois = async (destinationId, token) => {
  const url = `${cfg.cmsApiUrl}/api/v3/sites/${destinationId}/pois?status=enabled`;
  const res = await http.getJSON(url, token);
  return res;
};

export const fetchPoi = (poiUUID) =>
  new Promise((resolve, reject) => {
    const url = `${cfg.cmsApiUrl}/api/v3/pois/${poiUUID}`;
    http
      .getJSON(url)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const fetchPoiById = async (poiId, token) => {
  const url = `${cfg.cmsApiUrl}/api/v3/pois/by-id/${poiId}`;
  const res = await http.getJSON(url, token);
  return res;
};

export const postPoi = async (destinationId, poi, token) => {
  const url = `${cfg.cmsApiUrl}/api/v3/sites/${destinationId}/pois`;
  const res = await http.postJSON(url, poi, token);
  return res;
};

export const updatePoi = (poi, token) =>
  new Promise((resolve, reject) => {
    const url = `${cfg.cmsApiUrl}/api/v3/sites/${poi.siteId}/pois/${poi.id}`;
    http
      .putJSON(url, poi, token)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const deletePoi = (poi, token) => new Promise((resolve, reject) => {
  const url = `${cfg.cmsApiUrl}/api/v3/sites/${poi.siteId}/pois/${poi.id}`;
  http.deleteJSON(url, token)
    .then((response) => {
      resolve(response);
    })
    .catch((error) => {
      reject(error);
    });
});

export const duplicatePoi = (
  poiID,
  sourceDestinationId,
  targetDestinationId,
  token,
) =>
  new Promise((resolve, reject) => {
    const url = `${cfg.cmsApiUrl}/api/v3/sites/${sourceDestinationId}/pois/duplicate`;
    const req = { poiId: poiID, targetDestinationId };
    http
      .postJSON(url, req, token)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
